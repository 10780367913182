<style scoped lang="scss">
.page {
  padding: 2px 2px 2px 2px;
  width: 100%;
  height: 100%;
  display: grid;
  grid-gap: 2px;
  align-items: center;
  justify-items: center;
  grid-template:
    [row1-start] "operation operation operation operation operation" 36px [row1-end]
    [row2-start] "tags tags tags tags tags" auto [row2-end]
    [row3-start] "value value value value value" 15vw [row3-end]
    [row4-start] "keyboardDescription keyboard7 keyboard8 keyboard9 keyboardBackspace" 19vw [row4-end]
    [row5-start] "keyboardCalendar keyboard4 keyboard5 keyboard6 keyboardDelete" 19vw [row5-end]
    [row6-start] "keyboardA3 keyboard1 keyboard2 keyboard3 keyboardCancel" 19vw [row6-end]
    [row7-start] "keyboardA4 keyboardMenu keyboard0 keyboardComma keyboardSave" 19vw [row7-end]
    / 19vw 19vw 19vw 19vw auto;

  .operation {
    grid-area: operation;
    justify-self: center;
    .v-btn {
      width: 40vw;
    }
  }

  .value {
    grid-area: value;
    font-size: 2em;
  }

  .tags {
    grid-area: tags;
    width: 100%;
    height: 100%;
  }

  .keyboard {
    width: 100%;
    height: 100%;
    font-size: 1em;
    &.keyboardDescription {
      grid-area: keyboardDescription;
    }
    &.keyboardCalendar {
      grid-area: keyboardCalendar;
    }
    &.keyboardA3 {
      grid-area: keyboardA3;
    }
    &.keyboardA4 {
      grid-area: keyboardA4;
    }
    &.keyboard0 {
      grid-area: keyboard0;
    }
    &.keyboard1 {
      grid-area: keyboard1;
    }
    &.keyboard2 {
      grid-area: keyboard2;
    }
    &.keyboard3 {
      grid-area: keyboard3;
    }
    &.keyboard4 {
      grid-area: keyboard4;
    }
    &.keyboard5 {
      grid-area: keyboard5;
    }
    &.keyboard6 {
      grid-area: keyboard6;
    }
    &.keyboard7 {
      grid-area: keyboard7;
    }
    &.keyboard8 {
      grid-area: keyboard8;
    }
    &.keyboard9 {
      grid-area: keyboard9;
    }
    &.keyboardComma {
      grid-area: keyboardComma;
    }
    &.keyboardMenu {
      grid-area: keyboardMenu;
    }
    &.keyboardSave {
      grid-area: keyboardSave;
    }
    &.keyboardCancel {
      grid-area: keyboardCancel;
    }
    &.keyboardBackspace {
      grid-area: keyboardBackspace;
    }
    &.keyboardDelete {
      grid-area: keyboardDelete;
    }
  }
  .mdi {
    font-size: 1em;
  }
}
</style>
<template>
  <div class="page">
    <div class="operation">
      <v-btn
        @click="operation.payload.currency.type = 'depositing'"
        color="green"
        :variant="
          operation.payload.currency.type == 'depositing' ? 'outlined' : 'text'
        "
      >
        пополнение</v-btn
      >
      <v-btn
        @click="operation.payload.currency.type = 'withdrawal'"
        color="red"
        :variant="
          operation.payload.currency.type == 'withdrawal' ? 'outlined' : 'text'
        "
      >
        списание
      </v-btn>
    </div>
    <div class="tags"></div>
    <div class="value">
      <span v-if="operation.payload.currency.type == 'withdrawal'"> - </span>
      <span v-text="operation.payload.currency.value" />
      <span>&nbsp;{{ operation.payload.currency.sign }}</span>
    </div>

    <v-btn
      class="keyboard keyboardDescription"
      @click="descriptionOverlay = true"
    >
      <span class="mdi mdi-message-reply-text-outline" />
    </v-btn>
    <v-btn class="keyboard keyboardCalendar" @click="calendarOverlay = true">
      <span class="mdi mdi-calendar-clock-outline" />
    </v-btn>
    <v-btn disabled class="keyboard keyboardA3"></v-btn>
    <v-btn disabled class="keyboard keyboardA4"></v-btn>

    <v-btn class="keyboard keyboard1" @click="add('1')">1</v-btn>
    <v-btn class="keyboard keyboard2" @click="add('2')">2</v-btn>
    <v-btn class="keyboard keyboard3" @click="add('3')">3</v-btn>
    <v-btn class="keyboard keyboard4" @click="add('4')">4</v-btn>
    <v-btn class="keyboard keyboard5" @click="add('5')">5</v-btn>
    <v-btn class="keyboard keyboard6" @click="add('6')">6</v-btn>
    <v-btn class="keyboard keyboard7" @click="add('7')">7</v-btn>
    <v-btn class="keyboard keyboard8" @click="add('8')">8</v-btn>
    <v-btn class="keyboard keyboard9" @click="add('9')">9</v-btn>
    <v-btn class="keyboard keyboard0" @click="add('0')">0</v-btn>
    <v-btn class="keyboard keyboardBackspace" @click="backspace">
      <span class="mdi mdi-backspace" />
    </v-btn>
    <v-btn
      class="keyboard keyboardDelete"
      :disabled="!canDelete"
      @click="remove"
    >
      <span class="mdi mdi-delete" />
    </v-btn>
    <v-btn class="keyboard keyboardSave" :disabled="!canSave" @click="save">
      <span class="mdi mdi-check" />
    </v-btn>
    <v-btn class="keyboard keyboardCancel" @click="cancel">
      <span class="mdi mdi-cancel" />
    </v-btn>
    <v-btn class="keyboard keyboardMenu" @click="menu">
      <span class="mdi mdi-menu" />
    </v-btn>
    <v-btn
      class="keyboard keyboardComma"
      :disabled="hasComma"
      v-text="'.'"
      @click="add('.')"
    />
  </div>
  <v-overlay class="align-center justify-center" v-model="descriptionOverlay">
    <v-textarea
      style="width: 80vw"
      no-resize
      rows="5"
      autofocus
      variant="solo"
      v-model="operation.payload.description"
    />
  </v-overlay>
  <v-overlay class="align-center justify-center" v-model="calendarOverlay">
    <v-text-field
      type="datetime-local"
      variant="solo"
      @update:modelValue="updateCreated"
      :model-value="created"
    />
  </v-overlay>
  <v-dialog v-model="deleteDialog"></v-dialog>
</template>

<script>
import jobs from "@/store/jobs";
export default {
  name: "OperationEditor",
  components: {},
  data: function () {
    return {
      operation: {},
      tags: this.tags,
      tagsVariants: [],

      search: "",
      descriptionOverlay: false,
      calendarOverlay: false,
      deleteDialog: false,
    };
  },
  computed: {
    created() {
      let d = new Date(this.operation.created * 1000);
      let date = d.getDate() > 9 ? d.getDate() : "0" + d.getDate();
      let month =
        d.getMonth() + 1 > 9 ? d.getMonth() + 1 : "0" + (d.getMonth() + 1);
      let year = d.getFullYear();
      let hour = d.getHours() > 9 ? d.getHours() : "0" + d.getHours();
      let minutes = d.getMinutes() > 9 ? d.getMinutes() : "0" + d.getMinutes();
      return year + "-" + month + "-" + date + "T" + hour + ":" + minutes;
    },
    canSave() {
      return (
        parseFloat(this.operation.payload.currency.value) != 0 ||
        this.operation.payload.description != ""
      );
    },
    canDelete() {
      return !!this.operation.id;
    },
    hasComma() {
      return String(this.operation.payload.currency.value).indexOf(".") > -1;
    },
  },
  created() {
    let operation = this.$store.getters["operations/get"](
      this.$route.params.operationId
    );
    // задаём локальную операцию
    this.operation = JSON.parse(JSON.stringify(operation));
    // преобразуем значение в строку
    this.operation.payload.currency.value = String(
      this.operation.payload.currency.value
    );
  },
  methods: {
    updateCreated(date) {
      this.operation.created = Math.round(new Date(date) / 1000);
    },
    menu() {},
    save() {
      let operation = this.operation;
      // преобразуем значение в число
      operation.payload.currency.value = parseFloat(
        operation.payload.currency.value
      );
      // сохраняем с переходом на основной экран
      operation.walletId = this.$route.params.walletId;
      operation.id = operation.id ?? crypto.randomUUID();

      jobs.action("operation.save", this.operation).then(() => {
        this.$router.push({
          name: "wallet",
          params: { walletId: this.$route.params.walletId },
        });
      });
    },
    cancel() {
      this.$router.push({
        name: "wallet",
        params: { walletId: this.$route.params.walletId },
      });
    },
    remove() {
      jobs.action("operation.remove", this.operation).then(() => {
        this.$router.push({
          name: "wallet",
          params: { walletId: this.$route.params.walletId },
        });
      });
    },
    add(digit) {
      // если ведущий символ 0 и
      if (this.operation.payload.currency.value === "0" && digit != ".") {
        this.operation.payload.currency.value = "";
      }
      // добавляем символ
      this.operation.payload.currency.value += digit;
    },
    backspace() {
      // вырезаем последний символ
      this.operation.payload.currency.value =
        this.operation.payload.currency.value.substring(
          0,
          this.operation.payload.currency.value.length - 1
        );
      // если осталась пустая строка, добавляем 0
      if (this.operation.payload.currency.value === "") {
        this.operation.payload.currency.value = "0";
      }
    },
  },
};
</script>
