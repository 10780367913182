import { createRouter, createWebHistory } from "vue-router";
import WalletsView from "../views/WalletsView.vue";
import WalletView from "../views/WalletView.vue";
import AboutView from "../views/AboutView.vue";
import WalletConfigView from "../views/WalletConfigView.vue";
import OperationView from "@/views/OperationView";

const routes = [
  {
    path: "/",
    redirect: () => ({ name: "wallets" }),
  },
  {
    path: "/wallets",
    name: "wallets",
    component: WalletsView,
  },
  {
    path: "/wallets/:walletId/config",
    name: "walletConfig",
    component: WalletConfigView,
  },
  {
    path: "/wallets/:walletId/",
    name: "wallet",
    component: WalletView,
  },
  {
    path: "/wallets/:walletId/operations/:operationId/",
    name: "operation",
    component: OperationView,
    props: {},
  },
  {
    path: "/about",
    name: "about",
    component: AboutView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
