import { createStore } from "vuex";
import PWA from "./PWA";
import operations from "./operations";
import wallets from "./wallets";
import user from "./user";
import tags from "./tags";
import testData from "./default";

testData();

const store = createStore({
  /* options */
});

store.registerModule("PWA", PWA);
store.registerModule("operations", operations);
store.registerModule("wallets", wallets);
store.registerModule("user", user);
store.registerModule("tags", tags);

export default store;
