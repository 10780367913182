<template>
  <v-app>
    <v-layout>
      <v-app-bar color="primary" prominent>
        <v-app-bar-nav-icon
          variant="text"
          @click.stop="drawer = !drawer"
        ></v-app-bar-nav-icon>

        <v-toolbar-title>
          <slot name="title"></slot>
        </v-toolbar-title>
        <slot name="additional"></slot>
      </v-app-bar>

      <v-navigation-drawer v-model="drawer" location="left" temporary>
        <v-list>
          <v-list-item :to="{ name: 'wallets' }">Кошельки</v-list-item>
          <v-list-item>{{ version }}</v-list-item>
        </v-list>
        <template v-slot:append>
          <div class="pa-2">
            <v-btn block @click="clear"> Очистить все данные </v-btn>
          </div>
        </template>
      </v-navigation-drawer>

      <v-main class="overflow-auto">
        <slot></slot>
      </v-main>
    </v-layout>
  </v-app>
</template>

<script>
export default {
  props: {},
  data: function () {
    return {
      drawer: false,
      version: process.env.VUE_APP_VERSION,
    };
  },
  mounted() {},
  methods: {
    clear() {
      localStorage.clear();
    },
  },
};
</script>

<style scoped></style>
