let operations = {
  state: JSON.parse(localStorage.getItem("operations")) ?? [],
  getters: {
    get: (state) => (operationId) => {
      let operation = state
        .filter((operation) => operation.id == operationId)
        .find(() => true);
      if (!operation) {
        operation = {
          id: null,
          updated: null,
          created: Date.now() / 1000,
          walletId: null,
          payload: {
            description: "",
            currency: {
              sign: "₽",
              value: 0,
              type: "withdrawal",
            },
            tags: [],
          },
        };
      }
      return operation;
    },
    list: (state) => (walletId) => {
      return state.filter((operation) => operation.walletId === walletId);
    },
  },
  mutations: {
    save(state, operation) {
      // текущее время
      let timestamp = Math.round(Date.now() / 1000);
      // обновляем время добавления, если оно не задано
      if (!operation.created) {
        operation.created = timestamp;
      }
      // ищем нужный элемент
      for (let i = 0; i < state.length; i++) {
        // если нашли, удаляем и вставляем новый
        if (state[i].id === operation.id) {
          state.splice(i, 1);
          break;
        }
      }
      // если мы тут, значит не нашли нужный и просто добавляем
      state.push(operation);
      localStorage.setItem("operations", JSON.stringify(state));
    },
    remove(state, operation) {
      // ищем нужный элемент
      for (let i = 0; i < state.length; i++) {
        // если нашли, удаляем
        if (state[i].id === operation.id) {
          state.splice(i, 1);
          break;
        }
      }
      localStorage.setItem("operations", JSON.stringify(state));
    },
  },
  actions: {
    save({ commit }, operation) {
      commit("save", operation);
    },
    remove({ commit }, operation) {
      commit("remove", operation);
    },
  },
  modules: {},
  namespaced: true,
};

export default operations;
