<template>
  <App>
    <template v-slot:title>
      <v-skeleton-loader :loading="loading" type="text" color="primary">
        Настройки кошелька "{{ wallet.name }}"
      </v-skeleton-loader>
    </template>
    <div class="about">
      <h1>Wallet config page</h1>
    </div>
    {{ tags }}
    {{ value }}

    <textarea ref="textarea" v-model="textarea"></textarea>
    <div v-html="textarea.replace(['\n', ' '], ['<br />', '&nbsp;'])"></div>
  </App>
</template>

<script>
import App from "@/components/App";
// import NumPad from "@/components/NumPad";

export default {
  props: {},
  components: { App },
  data: function () {
    return {
      textarea: "test",
      step: "num-pad",
      value: "0",
      tags: ["штраф"],
      tagsVariants: [
        "аптека",
        "заправка",
        "машина",
        "штраф",
        "запчасти",
        "мойка",
        "колёса",
        "ремонт",
        "коврики",
        "вонючка",
        "продукты",
        "кафе",
        "день рождения",
        "праздник",
        "одежда",
        "обувь",
      ],
      loading: true,
      overlay: true,
      wallet: {},
    };
  },
  mounted() {
    // setInterval(() => {
    //   console.log(this.$refs.textarea.innerHTML);
    // }, 100);
    this.$store.getters["wallets/get"](this.$route.params.walletId)
      .then((data) => {
        this.wallet = data;
        this.loading = false;
        console.log(data);
      })
      .catch((error) => console.error(error));
  },
  computed: {},
  methods: {},
};
</script>
<style scoped lang="scss"></style>
