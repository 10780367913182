/* eslint-disable no-console */

import { register } from "register-service-worker";

// eslint-disable-next-line no-unused-vars
let deferredPrompt;

// window.addEventListener("beforeinstallprompt", (e) => {
//   deferredPrompt = e;
// });
//
// installApp.addEventListener('click', async () => {
//   if (deferredPrompt !== null) {
//     deferredPrompt.prompt();
//     const { outcome } = await deferredPrompt.userChoice;
//     if (outcome === 'accepted') {
//       deferredPrompt = null;
//     }
//   }
// });

// if (process.env.NODE_ENV === "production") {
register(`${process.env.BASE_URL}service-worker.js`, {
  ready() {
    console.log(
      "App is being served from cache by a service worker.\n" +
        "For more details, visit https://goo.gl/AFskqB"
    );
  },
  registered() {
    console.log("Service worker has been registered.");
  },
  cached() {
    console.log("Content has been cached for offline use.");
  },
  updatefound() {
    console.log("New content is downloading.");
  },
  updated() {
    console.log("New content is available; please refresh.");
    self.skipWaiting();
  },
  offline() {
    console.log(
      "No internet connection found. App is running in offline mode."
    );
  },
  error(error) {
    console.error("Error during service worker registration:", error);
  },
});
// }
