<template>
  <App>
    <template v-slot:default>
      <div class="about">
        <h1>This is an about page1</h1>
      </div>
    </template>
    <template v-slot:new>
      <div class="about">
        <h1>This is an about page2</h1>
      </div>
    </template>
  </App>
</template>

<script>
import App from "@/components/App";

export default {
  props: {},
  components: { App },
  data: function () {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
