<template>
  <App>
    <template v-slot:title>
      {{ wallet.name }}
    </template>
    <!--template v-slot:additional>
      <v-spacer></v-spacer>
      <template v-for="(total, i) in wallet.total" :key="i">
        <v-chip class="ma-1"
          >{{ total.total }} {{ total.currency.sign }}</v-chip
        >
      </template>
    </template-->
    <v-list>
      <template v-for="(item, i) in items" :key="i">
        <template v-if="item.type === 'date'">
          <v-list-item>
            <v-list-item-subtitle>
              {{ item.value }}
            </v-list-item-subtitle>
          </v-list-item>
        </template>
        <template v-if="item.type === 'operation'">
          <v-list-item
            :class="
              item.value.payload.currency.type == 'withdrawal' ? 'red' : 'green'
            "
            :to="{
              name: 'operation',
              params: { walletId: wallet.id, operationId: item.value.id },
            }"
          >
            <template v-slot:append>
              <template v-if="item.value.payload.currency.value">
                <v-chip>
                  {{ item.value.payload?.currency?.value }}
                  {{ item.value.payload?.currency.sign }}
                </v-chip>
              </template>
              <v-label class="ma-2">
                {{ date(item.value.created) }}
              </v-label>
            </template>
            <v-list-item-title
              v-text="item.value.payload?.description"
            ></v-list-item-title>
            <v-list-item-subtitle>
              <v-chip v-for="(tag, j) in item.value.payload?.tags" :key="j">
                {{ tag }}
              </v-chip>
            </v-list-item-subtitle>
          </v-list-item>
        </template>

        <v-divider></v-divider>
      </template>
    </v-list>
    <v-btn
      class="withdrawal"
      size="x-large"
      icon="mdi-minus"
      :to="{
        name: 'operation',
        params: {
          walletId: wallet.id,
          operationId: '-',
        },
      }"
    />
  </App>
</template>

<script>
import App from "@/components/App";

export default {
  components: { App },
  props: {},
  data: function () {
    return {
      drawer: false,
    };
  },
  computed: {
    wallet() {
      return this.$store.getters["wallets/get"](this.$route.params.walletId);
    },
    items() {
      // получаем и сортируем список операций
      let operations = this.$store.getters["operations/list"](
        this.$route.params.walletId
      ).sort((a, b) => {
        return b.created - a.created;
      });
      // метод получения даты
      let date = (timestamp) => {
        let d = new Date(timestamp * 1000);
        let date = d.getDate() > 9 ? d.getDate() : "0" + d.getDate();
        let month =
          d.getMonth() + 1 > 9 ? d.getMonth() + 1 : "0" + (d.getMonth() + 1);
        let year = d.getFullYear();
        return year + "." + month + "." + date;
      };

      let list = [];
      let last_date = "";
      for (let i in operations) {
        let d = date(operations[i].created);
        if (last_date != d) {
          list.push({
            type: "date",
            value: d,
          });
          last_date = d;
        }
        list.push({
          type: "operation",
          value: operations[i],
        });
      }
      return list;
    },
  },
  mounted() {},
  methods: {
    date(timestamp) {
      let d = new Date(timestamp * 1000);
      let hours = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
      let minutes = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
      return hours + ":" + minutes;
    },
  },
};
</script>

<style scoped lang="scss">
.depositing {
  position: absolute;
  right: 22px;
  bottom: 90px;
}
.withdrawal {
  position: absolute;
  right: 10px;
  bottom: 10px;
  background-color: red;
  color: white;
}
.v-list-item {
  &.green {
    background-color: #00ff0020;
  }
  &.red {
    background-color: #ff000020;
  }
}
</style>
