<template>
  <router-view />
</template>

<style lang="scss">
#app {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
</style>
<script>
export default {
  components: {},
};
</script>
