let duration = 1000 * 10;

export default {
  state: JSON.parse(localStorage.getItem("PWA")) ?? {
    promptTime: Date.now() - duration,
    installed: false,
  },
  getters: {
    CanInstall: (state) => () => {
      return !state.installed && Date.now() - duration > state.promptTime;
    },
  },
  mutations: {
    updatePromptTime(state) {
      state.promptTime = Date.now();
      localStorage.setItem("PWA", JSON.stringify(state));
    },
    installed(state) {
      state.installed = true;
      localStorage.setItem("PWA", JSON.stringify(state));
    },
  },
  actions: {
    updatePromptTime(context) {
      context.commit("updatePromptTime");
    },
    installed(context) {
      context.commit("installed");
    },
  },
  modules: {},
  namespaced: true,
};
