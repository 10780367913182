export default {
  state: JSON.parse(localStorage.getItem("user")) ?? {
    token:
      "ZWkwem5IaGpKSzUrTHg4ckVKZFh6KyswMlA4UDU0aVh6Zy9nK2Z5b1g3eUQ1R25Wck1hOHlyUFpSaHRVSXg5dHl4bDdCaGE3Q1huenU4L2g3WURQTm9xWUZUazBvbUd3WnR2eFZveFQxT2s9",
  },
  getters: {
    token(state) {
      return state.token;
    },
  },
  mutations: {},
  actions: {},
  modules: {},
  namespaced: true,
};
