export default function () {
  if (!localStorage.getItem("wallets")) {
    localStorage.setItem(
      "wallets",
      JSON.stringify([
        {
          id: "asd1",
          name: "первый кошелек",
          total: [
            {
              total: 100,
              currency: {
                sign: "₽",
              },
            },
          ],
          sorting: 1,
          config: {},
        },
        {
          id: crypto.randomUUID(),
          name: "второй кошелек",
          total: [
            {
              total: 200,
              currency: {
                sign: "₽",
              },
            },
          ],
          sorting: 2,
          config: {},
        },
      ])
    );
  }
}
