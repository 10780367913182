// логика обработки очередей

// eslint-disable-next-line no-unused-vars
// let template = {
//   type: "",
//   payload: {},
// };

import store from "@/store/index";

export default {
  action: (type, data) => {
    switch (type) {
      case "operation.save":
        return store.dispatch("operations/save", data);
      case "operation.remove":
        return store.dispatch("operations/remove", data);
      default:
        throw new Error("wrong action");
    }
  },
};
