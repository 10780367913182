import { createApp } from "vue";
import App from "@/App.vue";
import "@/registerServiceWorker";
import router from "@/router";
import store from "@/store";
import "@mdi/font/css/materialdesignicons.css";

// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import * as labsComponents from "vuetify/labs/components";

const vuetify = createVuetify({
  components: {
    ...components,
    ...labsComponents,
  },
  directives,
  icons: {
    defaultSet: "mdi",
  },
});

createApp(App).use(store).use(router).use(vuetify).mount("#app");

navigator.serviceWorker.addEventListener("controllerchange", () => {
  console.log("controllerchange1");

  // window.location.reload();
});

window.addEventListener("error", function (e) {
  console.error(e.message);
  if (e.message.includes("ResizeObserver loop limit exceeded")) {
    e.stopImmediatePropagation();
    e.preventDefault();
  }
});
