const name = "wallets";
export default {
  state: JSON.parse(localStorage.getItem(name) ?? "[]"),
  getters: {
    get: (state) => (walletId) =>
      state.filter((wallet) => wallet.id === walletId).find(() => true),
    list: (state) => {
      return state;
    },
  },
  mutations: {},
  actions: {},
  modules: {},
  namespaced: true,
};
