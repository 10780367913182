<template>
  <App>
    <template v-slot:title>Кошельки</template>
    <v-list>
      <template v-for="(wallet, i) in wallets" :key="i">
        <v-list-item :to="{ name: 'wallet', params: { walletId: wallet.id } }">
          <template v-slot:append>
            <template v-if="wallet.total.length > 0">
              <div v-for="(total, j) in wallet.total" :key="j">
                <v-chip class="ma-1">
                  {{ total.total }} {{ total.currency.sign }}
                </v-chip>
              </div>
            </template>
            <template v-if="wallet.total.length === 0">
              <v-chip class="ma-1">-</v-chip>
            </template>
            <v-btn
              :to="{ name: 'walletConfig', params: { walletId: wallet.id } }"
              variant="text"
              size="x-small"
              icon="mdi-cog"
            />
          </template>
          <v-list-item-title v-text="wallet.name"></v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
      </template>
    </v-list>
  </App>
</template>

<script>
import App from "@/components/App";

export default {
  components: { App },
  props: {},
  data() {
    return {};
  },
  computed: {
    wallets() {
      return [...this.$store.getters["wallets/list"]].sort((a, b) => {
        return a.sorting - b.sorting;
      });
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss"></style>
